import {SetStateAction, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {otpVerify, otpRequest, getUserByToken} from '../../../requests/_requests'
import {useIntl} from 'react-intl'
import SingleCharInputBox from "./partials/SingleCharInputBox";
import {useAuth} from '../core/Auth'
import AccountPrompt from "./partials/AccountPrompt";
import {useNavigate} from "react-router-dom";

const tr = {
    otpVerify: {
        id: "AUTH.OTP.VERIFY"
    },
    required: {
        id: "VALIDATION.REQUIRED"
    },
    otp: {
        id: "OTP"
    },
    otpInput: {
        id: "AUTH.INPUT.OTP"
    },
    next: {
        id: 'SUBMIT_AND_NEXT'
    },
    previous: {
        id: 'PREVIOUS'
    },
    otpAgain: {
        id: 'AUTH.OTP.AGAIN'
    },
    otpWait: {
        id: 'AUTH.OTP.WAIT'
    }
};

export function OtpVerify() {
    const [loading, setLoading] = useState(false)
    const [otp, setOtp] = useState('')
    const {formatMessage: text} = useIntl()
    const {phone, saveAuth, currentUser, setCurrentUser} = useAuth()
    const navigate = useNavigate()
    const [timer, setTimer] = useState(0);

    const formik = useFormik({
        initialValues: {otp: otp},
        onSubmit: async (values, {setStatus, setSubmitting, setErrors}) => {
            setLoading(true)
            try {
                const {data: auth} = await otpVerify(otp, typeof phone === "string" ? phone : '')
                saveAuth(auth)
                getUserByToken(auth.api_token).then((res) => {

                    setCurrentUser(res.data?.data)
                    if (!auth.registered ){
                        navigate('/registration/subscriptions')
                    }
                    else if (auth.forgot_password){
                        navigate('/profile/password')
                    }
                    else{
                        navigate('/dashboard')
                    }
                })
            } catch (error: any) {
                setErrors({otp: error.response.data.message})
                setStatus(error.response.data.message)
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    const onComplete = (otp: SetStateAction<string>) => {
        setOtp(otp)
        formik.handleSubmit()
    }

    useEffect(() => {
        if (!phone) {
            navigate('/auth/otp');
        }
        setTimer(90);

    }, [])

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
            return () => clearInterval(countdown);
        }
    }, [timer]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleOtpRequest = () => {
        if (!timer) {
            otpRequest(phone as string, false);
            setTimer(90);
        }
    };

    return (
        <form
            dir='rtl'
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <h1 className='text-dark fw-bolder'>
                {text(tr.otpVerify)}
            </h1>
            <AccountPrompt/>
            {/* end::Heading */}

            {/* begin::Form group */}
            <div className='fv-row mt-15 mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark mb-10'>
                    {text(tr.otpInput, {number: phone})}
                </label>

                <SingleCharInputBox onComplete={onComplete}/>

                {formik.touched.otp && formik.errors.otp && (
                    <div className='fv-plugins-message-container'>
                        <span className='text-danger' role='alert'>{formik.errors.otp}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}


            {/* begin::Action */}
            <div className='mb-10'>
                <button disabled={!!timer} onClick={handleOtpRequest} className='btn btn-outline btn-outline-info'>
                            <span className='indicator-label'>
                                {timer > 0 ? `${text(tr.otpWait)} ${formatTime(timer)}` : text(tr.otpAgain)}
                            </span>
                </button>
            </div>

            <div className='d-flex justify-content-between mb-10'>
                <button onClick={() => navigate(-1)} className='btn btn-outline btn-outline-primary'>
                        <span className='indicator-label'>
                            {text(tr.previous)}
                        </span>
                </button>

                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !otp}
                >
                    {!loading &&
                        <span className='indicator-label'>
                            {text(tr.next)}
                        </span>
                    }
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            {text(tr.next)}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}


        </form>
    )
}
