/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {useQueryResponseSub} from "../userSubs/SubsQueryResponseProvider";
import {MenuComponent} from "../../../../_metronic/assets/ts/components";
import {ID} from "../../../../_metronic/helpers";
import {useListView} from "../userSubs/SubListViewProvider";
import {payInvoice} from "../../../requests/_requests";
import clsx from "clsx";

const MultiActionCell: FC<Props> = ({id, status, invoice_id}) => {
    const {setItemIdForUpdate, setSecondModal, setShow} = useListView()
    const [loading, setLoading] = useState(false)
    const {query} = useQueryResponseSub()
    const queryClient = useQueryClient()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openEditModal = () => {
        setItemIdForUpdate(id)
    }

    const openRenewModal = () => {
        if (setSecondModal) {
            setSecondModal(id)
        }
    }

    const openShowModal = () => {
        if (setShow) {
            setShow(id)
        }
    }

    const paymentRequest = () => {
        if (invoice_id) {
            setLoading(true)
            payInvoice(invoice_id)
                .then(res => {
                    if (res?.data?.data?.redirect_url) {
                        window.location.assign(res.data.data.redirect_url);
                    }
                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <div className="d-flex gap-3 justify-content-evenly gap-md-3">
            <button className='btn btn-secondary btn-small-ct px-3' onClick={openShowModal}>
                مشاهده فاکتور
                <i className="fa-solid fa-file-invoice"></i>
            </button>
            <button className='btn btn-secondary btn-small-ct px-3' onClick={openEditModal}>
                تغییر رمز اتصال
                <i className="fa-solid fa-key"></i>
            </button>

            {
                status === 1
                    ?
                    <button className='btn btn-primary btn-small-ct px-3' onClick={paymentRequest}>
                    <span
                        className={clsx({
                            'indicator-progress': loading,
                            'indicator-label': !loading
                        })}
                        style={{display: 'block'}}>
                           پرداخت
                        {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                    </span>
                    </button>
                    :
                    <button className='btn btn-primary btn-small-ct px-3' onClick={openRenewModal}>
                        تمدید اشتراک
                        <i className="fa-solid fa-arrow-rotate-right"></i>
                    </button>
            }

        </div>
    )
}

type Props = {
    id: ID,
    status: number,
    invoice_id: string
}

export {MultiActionCell}
